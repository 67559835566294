import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "primeicons/primeicons.css";
import "./topBar.css";
class TopBar extends Component {
  state = {
    // templink: "",
    roomlist: JSON.parse(localStorage.getItem("roomlist")),
    roomid: localStorage.getItem("roomid"),
    roomnumber : ""
  };

  roomSelect = (i) => {
    localStorage.setItem("roomid", i.roomid);
    this.setState({ roomid: i.roomid, roomnumber:i.roomnumber });
  };

  componentDidMount() {
    var tempindex = this.state.roomlist.findIndex(
      (x) => x.roomid == this.state.roomid
    )
    if(tempindex !== -1)
    {
      this.setState({roomnumber : this.state.roomlist[tempindex].roomnumber})
    }
    else{
      {localStorage.setItem("roomid",this.state.roomlist[0].roomid)}
    } 
    
    // if (this.state.roomlist.length > 0) {
    //   this.setState({ roomid: this.state.roomlist[0].roomid });
    // }
    // localStorage.removeItem("templink");
  }

  render() {
    return (
      <>
        <div className="topBar">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                  <h1>Room Service</h1>
                  <div className="dropdown dropdownAlign">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Room - {this.state.roomnumber}
                    </button>
                    <ul className="dropdown-menu">
                      {this.state.roomlist.map((items, i) => (
                        <li onClick={() => this.roomSelect(items)}>
                          <a className="dropdown-item" href="javascript:;">
                          Room - {items.roomnumber}
                          </a>
                        </li>
                      ))}
                      {/* <li>
                      <a className="dropdown-item" href="#">
                        R100
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        R101
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        R102
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        R103
                      </a>
                    </li> */}
                    </ul>
                  </div>
                  <NavLink className="logoutBtn" id="" to="/logout">
                    <i className="pi pi-sign-out"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default TopBar;
// export default function TopBar() {
//   return (
//     <>
//       <div className="topBar">
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <div className="d-flex justify-content-between align-items-center">
//                 <h1>Room Service</h1>
//                 <div className="dropdown dropdownAlign">
//                   <button
//                     className="btn btn-secondary dropdown-toggle"
//                     type="button"
//                     data-bs-toggle="dropdown"
//                     aria-expanded="false"
//                   >
//                     Select Room
//                   </button>
//                   <ul className="dropdown-menu">
//                     <li>
//                       <a className="dropdown-item" href="#">
//                         R100
//                       </a>
//                     </li>
//                     <li>
//                       <a className="dropdown-item" href="#">
//                         R101
//                       </a>
//                     </li>
//                     <li>
//                       <a className="dropdown-item" href="#">
//                         R102
//                       </a>
//                     </li>
//                     <li>
//                       <a className="dropdown-item" href="#">
//                         R103
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//                 <NavLink
//                   className=""
//                   id=""
//                   to="/logout"
//                 ><i className="pi pi-sign-out logout"></i></NavLink>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
