import React, { lazy, Suspense, Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './App.css';
//import axios from './axios-api';
import Layout from "./hoc/Layout/Layout";
import Logout from "./containers/Auth/Logout/Logout";
import Aux1 from "./hoc/Aux1/Aux1";
import * as actions from "./store/actions/index";
// import CacheBuster from "./CacheBuster";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import Spinner from "./components/UI/Spinner/Spinner";
// import axios from "./axios-api";
//import { DataTable } from "primereact/datatable";

// import ReactGA from "react-ga4";
import packageJson from '../package.json';
global.appVersion = packageJson.version;

//const Auth = lazy(() => import("./containers/Auth/Auth"));

// const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
// const events = lazy(() => import("./components/events/events"));
 const Launch = lazy(() => import("./components/Launch/Launch"));
 const Login = lazy(() => import("./components/Launch/Login"));
 const Auth = lazy(() => import("./containers/Auth/Auth"));
// const Configuration = lazy(() => import("./components/Configuration/Configuration"));
// const Rooms = lazy(() => import("./components/Rooms/Rooms"));
// const Users = lazy(() => import("./components/Users/Users"));
// const Feedback = lazy(() => import("./components/Feedback/Feedback"));
const AllServices = lazy(() => import("./components/allServices/allServices"));
const Request = lazy(() => import("./components/requests/requests"));
const Feedback = lazy(() => import("./components/feedback/feedback"));
const Loginpage = lazy(() => import("./components/login/login"));
// const TRACKING_ID = "";
// ReactGA.initialize(TRACKING_ID);

//ITS One Login
// const windowUrl = window.location.hash;
// let paramString = windowUrl.split("?")[1];

// if (paramString != undefined) {
//   var queryParams = paramString.split("&").reduce(function (q, query) {
//     var chunks = query.split("=");
//     var key = chunks[0];
//     if (key === "DT") {
//       var value = decodeURIComponent(chunks[1]);
//       value = isNaN(Number(value)) ? value : Number(value);
//       if (value !== undefined) localStorage.setItem("itstoken", value);
//     }

//     return value;
//   }, {});

//   // if (queryParams !== undefined) {
//   //   localStorage.setItem("itstoken", queryParams);
//   // }
// }



class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
    // if (localStorage.itstoken !== "" && localStorage.itstoken !== undefined) {
    //   this.props.onOneLoginSignup();
    // }
  }

  render() {
    let routes = (
      <Suspense
        fallback={
          // <div className="loader h-100">
          //   <div className="d-flex justify-content-center align-items-center h-100">
          //     <div className="spinner-border" role="status">
          //       <span className="sr-only"></span>
          //     </div>
          //   </div>
          // </div>
          <Spinner />
        }
      >
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/" exact component={Auth} />
          {/* <Route path="/sso" component={SSO} /> */}
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
    
    if (this.props.isAuthenticated) {
      routes = (
        <Suspense
          fallback={
            // <div className="loader h-100">
            //   <div className="d-flex justify-content-center align-items-center h-100">
            //     <div className="spinner-border" role="status">
            //       <span className="sr-only"></span>
            //     </div>
            //   </div>
            // </div>
            <Spinner />
          }
        >
          <Switch>
            <Route exact path="/" component={Launch} />
            <Route path="/services" component={AllServices} />
            <Route path="/requests" component={Request} />
            <Route path="/feedback" component={Feedback} />
            <Route path="/loginpage" component={Loginpage} /> 
            {/* <Route path="/configuration" component={Configuration} />
            <Route path="/rooms" component={Rooms} /> 
            <Route path="/users" component={Users} /> 
            <Route path="/feedback" component={Feedback} /> 
            <Route path="/loginpage" component={Loginpage} />  */}
            <Route path="/logout" component={Logout} />
            <Route exact path="/Launch" component={Launch} />
            <Route path="**" render={
                () => (
                    <article>
                        <h1 className="text-danger">No Route Configured!</h1>
                        <h4 className="text-danger">Please check your Route Configuration</h4>
                    </article>
                )
            } />
          </Switch>
        </Suspense>
      );
    }
    return (
      <Aux1>
        <CacheBuster
          currentVersion={version}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          loadingComponent={
            // <div className="loader h-100">
            //   <div className="d-flex justify-content-center align-items-center h-100">
            //     <div className="spinner-border" role="status">
            //       <span className="sr-only"></span>
            //     </div>
            //   </div>
            // </div>
            <Spinner />
          } //If not pass, nothing appears at the time of new version check.
          metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        >
          {/* <div className="App"> */}
                <Layout>{routes}</Layout>
              {/* </div> */}
        </CacheBuster>
        
      </Aux1>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    // onOneLoginSignup: () => dispatch(actions.authCheckOneLogin()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
