import React from "react";
import Aux1 from "../../../hoc/Aux1/Aux1";
//import * as Datetime from "react-datetime";
//import DatePicker from "react-datepicker";


//import classes from './Input.css';

const input = props => {
  let inputElement = null;
  //const inputClasses = [classes.InputElement];

  if (props.invalid && props.shouldValidate && props.touched) {
    //inputClasses.push(classes.Invalid);
  }

  switch (props.elementType) {
    case "input":
      inputElement = (
        <input
          //className={inputClasses.join(' ')}
          className={props.classType}
          title={props.title}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          //className={inputClasses.join(' ')}
          className={props.classType}
          title={props.title}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          //className={inputClasses.join(' ')}
          className={props.classType}
          title={props.title}
          value={props.value}
          onChange={props.changed}
        >
          {props.elementConfig.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
   
    default:
      inputElement = (
        <input
          //className={inputClasses.join(' ')}
          className={props.classType}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return (
    <Aux1>
      {props.shouldValidate.required ? (
        <span style={{ color: "red" }}>*</span>
      ) : null}
      {inputElement}
    </Aux1>
  );
};

export default input;
