import React from "react";
import { useState } from 'react';
import "./login.css";
export default function LoginPage() {
    const [value, setValue] = useState('');
    const handleChange = event => {
        const result = event.target.value.replace(/\D/g, '');
        setValue(result);
    };
    return (
        <div className="loginContainer d-flex flex-column flex-grow-1 justify-content-center align-items-center">
            <div className="loginPage1">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                    <div className="logo"></div>
                    <h1>Welcome<br /> to<br />Al-Raihaan</h1>
                    <h2>Login</h2>
                    <div className="form-field">
                        <label >ITS</label>
                        <input
                            type="text"
                            value={value}
                            onChange={handleChange}
                            id="inputITSnum"
                            className="form-control"
                        />
                    </div>
                    <button type="button" className="btn btn-primary">Login</button>
                </div>
            </div>
            <div className="loginPage2">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                    <div className="logo"></div>
                    <h1>Welcome</h1>
                    <h2>Yusuf Rampurawala</h2>
                    <h3>Please select room</h3>
                    <div className="selectRoom">
                        <button type="button" className="btn btn-outline ">R203</button>
                        <button type="button" className="btn btn-outline ">R204</button>
                        <button type="button" className="btn btn-outline ">R205</button>
                        <button type="button" className="btn btn-outline ">R206</button>
                        <button type="button" className="btn btn-outline ">R207</button>
                        <button type="button" className="btn btn-outline ">R208</button>
                    </div>
                    <button type="button" className="btn btn-primary">Click here to raise request</button>

                </div>
            </div>
        </div>
    )
}