import React, { Component } from "react";
import { connect } from "react-redux";

import Aux1 from "../Aux1/Aux1";

//import classes from "./Layout.css";

// import '../../styles/css/bootstrap-datetimepicker.min.css';
// import '../../styles/css/fontawesome-all.min.css';

// import '../../styles/css/jquery.mCustomScrollbar.min.css';

// import '../../styles/css/customScroll.css';
// import "react-datepicker/dist/react-datepicker.css";
// import '../../styles/css/tooltip.css';
// import '../../styles/css/utility.css';
// import '../../styles/css/style.css';
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";

//import '../../styles/css/responsive.css';

//import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
// import LeftNavigation from "../../components/Navigation/LeftNavigation/LeftNavigation";
//import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
//import TopHeader from '../../components/Navigation/TopHeader/TopHeader';
// import TopHeader from "../../components/Navigation/TopHeader/TopHeader";
import Login from "../../containers/Auth/Auth";
import Loginpage from "../../components/login/login";
//import SSO from '../../containers/Auth/SSO';
//import Footer from '../../components/footer/footer';
//import PopUp from '../../components/PopUp/PopUp';
import TopBar from "../../components/topBar/topBar";
import BottomNav from "../../components/bottomNav/bottomNav";

class Layout extends Component {
  state = {
    showSideDrawer: false,
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    //if (this.props.isAuthenticated && this.props.children._self.props.location.pathname!="/") {
    if (
      this.props.isAuthenticated && (this.props.children._owner.pendingProps.location.pathname=="/")
    ) {
      return (
      <article className="siteContainer d-flex flex-column flex-grow-1">
        <div className="innerContent d-flex flex-column flex-grow-1">
          {this.props.children}
        </div>
      </article>);
    }
    else if (this.props.isAuthenticated) {
      return (
        <Aux1>
          <article className="siteContainer d-flex flex-column flex-grow-1">
            <div className="innerContent d-flex flex-column flex-grow-1">
              <TopBar isAuth={this.props.isAuthenticated} />
              {this.props.children}
              <BottomNav isAuth={this.props.isAuthenticated} />
            </div>
          </article>
          {/* <Footer/> */}
        </Aux1>
      );
    } else {
      return (
        <Aux1>
          {/* <main>{this.props.children}</main> */}
          <article className="siteContainer d-flex flex-column flex-grow-1">
            <div className="innerContent d-flex flex-column flex-grow-1">
              {/* <Loginpage></Loginpage> */}
              <Login></Login>
            </div>
          </article>

          {/* <SSO></SSO> */}
        </Aux1>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(Layout);
