import axios from 'axios';

const instance = axios.create({
     baseURL:"/API/",
    url:"https://roomservice.babjisoftech.com/API/"
    // baseURL:"https://roomservice.babjisoftech.com/API/",
//    baseURL:'https://localhost:44338/',
//    url:"https://localhost:44338/"

});

export default instance;