import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./bottomNav.css";

export default function BottomNav() {
  
  return (
    <div className="bottomNavContainer">
      <nav className="navbar fixed-bottom">
        <div className="container-fluid">
          <ul className="list-inline d-flex w-100 justify-content-around align-items-center m-0">
            <li>
              <NavLink
                to={"/services"}
                style={{ textDecoration: "none" }}
                activeClassName="active"
                className={`btn navLink d-flex align-items-center homeLink`}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/requests"}
                style={{ textDecoration: "none" }}
                activeClassName="active"
                className={`btn navLink d-flex align-items-center requestLink`}
              >
                Requests
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/feedback"}
                style={{ textDecoration: "none" }}
                activeClassName="active"
                className={`btn navLink d-flex align-items-center feedbackLink `}
              >
                Feedback
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
