import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
//import classes from "./Auth.css";
import * as actions from "../../store/actions/index";
import { updateObject, checkValidity } from "../../shared/utility";
import { toast } from "react-toastify";
// import "./Auth.css";
import $ from "jquery";
const logo = require("../../images/logo.jpg");
//let width = $(document).width();

class Auth extends Component {
  state = {
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "number",
          // autoFocus: localStorage.getItem("LoginID") != null ? false : true,
          id: "email",
          //placeholder: "Mail Address"
        },
        value:
          localStorage.tempuser !== "" && localStorage.tempuser !== undefined
            ? localStorage.tempuser
            : "",
        validation: {
          required: false,
          isEmail: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          // autoFocus: localStorage.getItem("LoginID") != null ? true : false,
          //placeholder: "Password"
        },
        value:
          localStorage.tempuser !== "" && localStorage.tempuser !== undefined
            ? localStorage.tempuser
            : "1",
        validation: {
          required: false,
          minLength: 6,
        },
        valid: false,
        touched: false,
      },
    },
    isKhidmatGuzar: true,
    isRememberMe: false,
    validationerror: "",
    isCleared: false,
    loading: false,
    showerror: true
  };

  componentDidMount() {
    if (localStorage.temptoken !== "" && localStorage.temptoken !== undefined) {
      this.ssosubmitHandler();
    }
    if (this.props.authRedirectPath !== "/") {
      this.props.onSetAuthRedirectPath();
    }
    if (localStorage.getItem("LoginID") != null) {
      this.setState({ isRememberMe: true });
      const updatedControls = updateObject(this.state.controls, {
        email: updateObject(this.state.controls["email"], {
          value: localStorage.getItem("LoginID"),
          valid: checkValidity(
            localStorage.getItem("LoginID")
            //,this.state.controls["email"].validation
          ),
          touched: true,
        }),
      });
      this.setState({ controls: updatedControls });
    }
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true,
      }),
    });
    this.setState({ controls: updatedControls,showerror:false });
  };

  ssosubmitHandler = () => {
    //event.preventDefault();
    this.setState({ isCleared: false });
    if (this.state.controls["email"].value.trim() === "") {
      this.setState({ validationerror: "Username/ITS required" });
    } else if (this.state.controls["password"].value.trim() === "") {
      this.setState({ validationerror: "Password required" });
    } else {
      this.setState({ validationerror: "", loading: true });
      this.props.onAuth(
        this.state.controls.email.value,
        this.state.controls.password.value,
        this.state.isRememberMe
      );
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ isCleared: false });
    if (this.state.controls["email"].value.trim() === "") {
      // this.setState({ validationerror: "ITS required" });
      toast.error("ITS required");
    } else if (this.state.controls["password"].value.trim() === "") {
      // this.setState({ validationerror: "Password required" });
      toast.error("Password required");
    } else {
      this.setState({ validationerror: "", loading: true,showerror:true });
      this.props.onAuth(
        this.state.controls.email.value,
        this.state.controls.password.value,
        this.state.isRememberMe
      );
    }
   
  };

  checkboxHandler = () => {
    this.setState((prevState) => {
      return { isRememberMe: !prevState.isRememberMe };
    });
  };
  clearDataHandler = () => {
    const cleardata = updateObject(this.state.controls, {
      email: updateObject(this.state.controls["email"], {
        value: "",
        touched: false,
      }),
      password: updateObject(this.state.controls["password"], {
        value: "",
        touched: false,
      }),
    });
    this.setState({
      controls: cleardata,
      isKhidmatGuzar: true,
      validationerror: "",
      isCleared: true,
    });
    // document.getElementById("email").focus();
  };

  render() {
    let AuthLoading = <Spinner />;
    // let errorMessage = null;
    
    if (
      this.props.error &&
      this.state.validationerror.trim() === "" &&
      !this.state.isCleared && this.state.showerror
    ) {
      // errorMessage = <p className="error">{this.props.error}</p>;
      toast.error(this.props.error);
    }

    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    return (
      <>
        <div className="loginContainer d-flex flex-column flex-grow-1  align-items-center">
          <div className="loginPage1">
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
              <div className="logo d-flex"><img className="img-fluid" src={logo}></img></div>
              <h1>
                <span style={{fontSize:"30px"}}>Welcome
                <br /> to
                <br /></span>
                Al-Raihaan
              </h1>
              
              {/* <h2>Login</h2> */}
              {authRedirect}
              {this.props.loading ? AuthLoading : null}
              {/* <form onSubmit={this.submitHandler}> */}
                {/* {errorMessage} */}
                <div className="form-field">
                  <label htmlFor="ITS">ITS</label>
                  <Input
                    key={"email"}
                    classType="form-control usrimg"
                    elementType={this.state.controls["email"].elementType}
                    elementConfig={this.state.controls["email"].elementConfig}
                    value={this.state.controls["email"].value}
                    invalid={!this.state.controls["email"].valid}
                    shouldValidate={this.state.controls["email"].validation}
                    touched={this.state.controls["email"].touched}
                    changed={(event) =>
                      this.inputChangedHandler(event, "email")
                    }
                  />
                  {/* <label>ITS</label>
                <input
                  type="text"
                  value={value}
                  onChange={handleChange}
                  id="inputITSnum"
                  className="form-control"
                /> */}
                </div>
                {/* <div className="form-field">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <Input
                    key={"password"}
                    classType="form-control pswdimg"
                    elementType={this.state.controls["password"].elementType}
                    elementConfig={
                      this.state.controls["password"].elementConfig
                    }
                    value={this.state.controls["password"].value}
                    invalid={!this.state.controls["password"].valid}
                    shouldValidate={this.state.controls["password"].validation}
                    touched={this.state.controls["password"].touched}
                    changed={(event) =>
                      this.inputChangedHandler(event, "password")
                    }
                  />
                </div> */}
                {/* <Button classType="btn" className="btn btn-primary">Login</Button> */}
                <button
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={this.submitHandler}
                >
                  Login
                </button>
              {/* </form> */}
            </div>
          </div>
        </div>
        <footer className=" w-full">
              {/* <nav className="navbar fixed-bottom navbar-light "> */}
              <nav className="navbar navbar-dark ">
                {/* <div>
                  Copyright © 2023 Sigatul Mazaraat - All rights reserved
                </div> */}
                <div>Version - {global.appVersion}</div>
                <div className="">
                  Designed &amp; Developed by{" "}
                  <a
                    href="https://babjisoftech.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-underline"
                    style={{color:"#fff"}}
                  >
                    Babji Softech
                  </a>
                </div>
              </nav>
            </footer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    //buildingBurger: state.burgerBuilder.building,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, isRememberMe) =>
      dispatch(actions.auth(email, password, isRememberMe)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
