import React from "react";
// import { ProgressSpinner } from "primereact/progressspinner";
import "./Spinner.css";

const spinner = () => (
  <div className="loaderOverlay flex justify-content-center align-items-center">
    {/* <ProgressSpinner strokeWidth={4}/> */}
    <div className="loader h-100">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
        </div>
      </div>
    </div>
  </div>
);

export default spinner;
